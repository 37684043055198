import React from 'react';

// Components
import { Wrapper, BodyWrapper, BlackBodyWrapper } from '../components/BodyWrapper/BodyWrapper.styles';
import Header from '../components/Header';
import HowItWorks from '../components/HowItWorks';
import TextAndImage from '../components/TextAndImage';
import Footer from '../components/Footer';


const HowItWorksPage = () => {

    return (
        <>
            <Header />
            <Wrapper>
                <BodyWrapper>
                    <HowItWorks />
                </BodyWrapper>
                <BlackBodyWrapper>
                    <TextAndImage />
                </BlackBodyWrapper>
            </Wrapper>
            <Footer />
        </>
        
    );

}

export default HowItWorksPage;