import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    .second-info-container {
        flex-direction: row-reverse;
    }

    @media screen and (max-width: 1024px) {
        margin: 80px 0;
        .second-info-container {
            flex-direction: column-reverse;
        }
    }
`;

export const TextAndImageContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 60px;
    width: 100%;
    margin: 70px 0;

    .screen-img {
        width: 322px;
        height: 675px;
        object-fit: cover;
        border-radius: 16px;
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column-reverse;
        margin: 48px 0;
    }
`;

export const TextContainer = styled.div`
    max-width: 660px;

    h4 {
        color: #D88EFD;
        font-size: 40px;
        font-weight: 300;
        line-height: normal;
    }

    @media screen and (max-width: 1024px) {
        max-width: 660px;
    }
`;

export const TitleAndTextContainer = styled.div`
    h5 {
        margin: 56px 0 16px 0;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
    }

    p {
        color: #EED0F2;
    }
`;