import React from 'react';

// Components
import { Wrapper, BodyWrapper } from '../components/BodyWrapper/BodyWrapper.styles';
import Header from '../components/Header';
import AboutUs from '../components/AboutUs';
import Footer from '../components/Footer';

const AboutUsPage = () => {

    return (
        <>
            <Header />
            <Wrapper>
                <BodyWrapper>
                    <AboutUs />
                </BodyWrapper>
            </Wrapper>
            <Footer />
        </>
        
    );

}

export default AboutUsPage;