import styled from "styled-components";

export const Wrapper = styled.div`
    .alert {
        padding: 10px 20px;
        border-radius: 4px;
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 20px 60px;
        width: 60%;
        max-width: 200px;
        text-align: center;
    }
    
    .alert.error {
        background-color: #f8d7da;
        border: 1px solid #f5c6cb;
        color: #721c24;
    }
    
    .alert.success {
        background-color: #d4edda;
        border: 1px solid #c3e6cb;
        color: #155724;
    }
`;