import React, { useState, useEffect } from 'react';
import { Wrapper } from "./Alert.styles";

const Alert = ({ message, type, onClose }) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
            onClose();
        }, 5000);

        return () => clearTimeout(timer);
    }, [onClose]);

    return visible ? (
        <Wrapper>
            <div className={`alert ${type}`}>
                {message}
            </div>
        </Wrapper>
    ) : null;
};

export default Alert;