import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 70px 0;
`;

export const ContactUsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 60px;

    h2 {
        font-size: 30px;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.6px;
        margin-bottom: 8px;
    }

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 150.188%;
        opacity: 0.8;
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;
    }
`;

export const ContactContainer = styled.div`
    max-width: 660px;

    form {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        margin-top: 29px;

        input, textarea {
            padding: 12px 14px;
            border-radius: 5px;
            border: 1px solid rgba(255, 255, 255, 0.20);
            background: rgba(255, 255, 255, 0.05);
            color: rgba(255, 255, 255, 0.60);

            &::placeholder {
                color: rgba(255, 255, 255, 0.60);
            }
        }

        textarea {
            resize: none;
            height: 109px;
        }

        button {
            height: 44px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.16px;
        }
    }

    @media screen and (max-width: 1024px) {
        max-width: 100%;
    }
`;

export const OurSocialsContainer = styled.div`
    max-width: 440px;

    @media screen and (max-width: 1024px) {
        margin: 70px 0;
    }
`;

export const SocialsContainer = styled.div`
    margin-top: 5px;
`;

export const SocialContainer = styled.div`
    display: flex;
    gap: 20px;
    margin-top: 24px;
`;