import React from "react";
import { Wrapper, HowItWorksContainer, HowItWorksTextContainer } from "./HowItWorks.styles";

import HowItWorksImg from "../../assets/how-it-works.png"

const HowItWorks = () => {
    return (
        <Wrapper>
            <HowItWorksContainer>
                <HowItWorksTextContainer>
                    <h3>GET INFORMED</h3>
                    <h1>How it works?</h1>
                    <p>At Glacies, we understand your skin's unique narrative. By integrating cutting-edge sequencing technology and microbiome insights with advanced AI, we offer a comprehensive skin analysis that delves beyond the surface to uncover the intricate biology of your skin.</p>
                    <p>Our approach customizes a skincare regimen precisely tailored to your needs. Our proprietary algorithm synthesizes data from three key sources: your skin's microbiome via swab analysis, a detailed questionnaire capturing your unique habits, and facial imaging to construct a distinct skin profile. </p>
                    <p>Ready to discover what your skin truly needs? Let's embark on this journey together.</p>
                </HowItWorksTextContainer>
                <img src={HowItWorksImg} alt="How it works" />
            </HowItWorksContainer>
        </Wrapper>
    )
};

export default HowItWorks;