import React from "react";
import { useNavigate } from 'react-router-dom';
import { Wrapper, TextContainer, ButtonsContainer } from "./HomeIntroContainer.styles";

import CellImage from "../../assets/cell.png";

const HomeIntroContainer = () => {
    const navigate = useNavigate();
    
    return (
        <Wrapper>
            <TextContainer>
                <h1>Next Generation of Precision Skincare</h1>
                <p>Discover the Future of Personalized Skincare</p>
                <ButtonsContainer>
                    <button onClick={() => window.open('https://web.glacies.bio', '_blank')} className="white-button">Join us</button>
                    <button className="black-button" onClick={() => navigate('/about-us')}>What do we do?</button>
                </ButtonsContainer>
                <span>Powered by Microbiomics & AI</span>
            </TextContainer>
            <img src={CellImage} alt="Cell" />
        </Wrapper>
        
        
    )
};

export default HomeIntroContainer;