import React from 'react';

// Components
import { Wrapper, BodyWrapper, BlackBodyWrapper } from '../components/BodyWrapper/BodyWrapper.styles';
import Header from '../components/Header';
import HomeIntroContainer from '../components/HomeIntroContainer';
import Facts from '../components/Facts';
import PersonalisedSkincare from '../components/PersonalisedSkincare';
import CollaborationsAndPartners from '../components/CollaborationsAndPartners';
import Footer from '../components/Footer';

const HomePage = () => {

    return (
        <>
            <Header />
            <Wrapper>
                <BodyWrapper>
                    <HomeIntroContainer />
                </BodyWrapper>
                <BlackBodyWrapper>
                    <Facts />
                    <PersonalisedSkincare />
                </BlackBodyWrapper>
                <BodyWrapper>
                    <CollaborationsAndPartners />
                </BodyWrapper>
            </Wrapper>
            <Footer />
        </>
        
    );

}

export default HomePage;