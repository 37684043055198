import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 70px 0 200px 0;

    @media screen and (max-width: 500px) {
        margin: 70px 0;
    }
`;

export const BtnContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 32px;
    margin: 56px 0 16px 0;

    button {
        height: 56px;
    }

    @media screen and (max-width: 500px) {
        flex-direction: column;
        width: 100%;
    }
`;

export const CollaborationsAndPartnersContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1240px;
    width: 100%;

    h2 {
        color: #D88EFD;
        text-align: center;
        font-size: 32px;
        font-weight: 500;
        line-height: 42px;
        margin-top: 40px;
    }

    p {
        text-align: center;
        margin-top: 24px;
        max-width: 500px;
    }

    h3 {
        color: #D88EFD;
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        line-height: 42px;
        margin: 64px 0 16px 0;
    }
`;

export const CollaborationsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    justify-content: center;
    gap: 20px;

    @media screen and (max-width: 830px) {
        flex-wrap: wrap;
    }
`;

export const CollaborationContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        max-width: 108px;
        text-align: center;
    }

    img {
        height: 90px;
    }

    @media screen and (max-width: 830px) {
        p {
            font-size: 14px;
        }
    }
`;

export const PartnersContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 60px;

    img {
        height: 95px;
    }
`;