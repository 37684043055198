import styled from "styled-components";

export const Wrapper = styled.footer`
    display: flex;
    flex-direction: column;
    padding: 56px 80px;
    border-top: 1px solid #E7E8EB;

    @media screen and (max-width: 830px) {
        padding: 56px 24px;
    }
`;

export const TopContainer = styled.div`
    display: flex;
    justify-content: start;
    width: 100%;

    img {
        height: 43px;
    }
`;

export const MidContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 152px;

    img {
        width: 265px;
        height: 71px;
    }

    button {
        height: 50px
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        align-items: baseline;
        height: auto;
        gap: 48px;
        margin: 48px 0;
    }
`;

export const LinksContainer = styled.div`
    display: flex;
    gap: 40px;

    a {
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
        text-decoration: none;
    }

    @media screen and (max-width: 580px) {
        flex-direction: column;
    }
`;

export const BottomContainer = styled.div`
    display: flex;
    justify-content: space-between;

    p {
        opacity: 0.5;
    }

    @media screen and (max-width: 935px) {
        flex-direction: column-reverse;
        gap: 48px;
    }
`;

export const ShareContainer = styled.div`
    display: flex;
    gap: 64px;

    img {
        width: 24px;
        height: 24px;
    }

    @media screen and (max-width: 580px) {
        gap: 48px;
    }
`;