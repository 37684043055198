import React from "react";
import { Wrapper, BtnContainer, CollaborationsAndPartnersContainer, CollaborationsContainer, CollaborationContainer, PartnersContainer } from "./CollaborationsAndPartners.styles";

import Collaboration1 from "../../assets/company_default.svg"
import Collaboration2 from "../../assets/clinics_default.svg"
import Collaboration3 from "../../assets/uni_default.svg"
import Collaboration4 from "../../assets/prof_default.svg"
import Partner1 from "../../assets/luxembourg-university.svg"
import Partner2 from "../../assets/research_lux.svg"


const CollaborationsAndPartners = () => {
    return (
        <Wrapper>
            <CollaborationsAndPartnersContainer id="collaborations">
                <h2>Collaborations & Partnerships</h2>
                <p>As you'll have gathered, we're open and honest and would love to have you as our partner!</p>
                <BtnContainer>
                    <button className="white-button">Become a Partner</button>
                    <button className="black-button">Book a Meeting</button>
                </BtnContainer>

                <h3>Our Partners</h3>
                <PartnersContainer>
                    <img src={Partner1} alt="Partner" />
                    <img src={Partner2} alt="Partner" />
                </PartnersContainer>

                <h3>Open for Collaborations</h3>
                <CollaborationsContainer>
                    <CollaborationContainer>
                        <img src={Collaboration1} alt="Collaboration" />
                        <p>Skincare & Cosmetics Companies</p>
                    </CollaborationContainer>
                    <CollaborationContainer>
                        <img src={Collaboration2} alt="Collaboration" />
                        <p>Clinics & Diagnostic Centers</p>
                    </CollaborationContainer>
                    <CollaborationContainer>
                        <img src={Collaboration3} alt="Collaboration" />
                        <p>Academic<br/>Institutions</p>
                    </CollaborationContainer>
                    <CollaborationContainer>
                        <img src={Collaboration4} alt="Collaboration" />
                        <p>Skincare Professionals</p>
                    </CollaborationContainer>
                </CollaborationsContainer>
            </CollaborationsAndPartnersContainer>
        </Wrapper>
    )
};

export default CollaborationsAndPartners;