import React, {useRef, useState} from "react";
import emailjs from '@emailjs/browser';
import { Wrapper, ContactUsContainer, ContactContainer, OurSocialsContainer, SocialsContainer, SocialContainer } from "./ContactUs.styles";
import Alert from "../Alert";

import Social1 from "../../assets/mdi-linkedin.svg"
import Social2 from "../../assets/ri-instagram.svg"
import Social3 from "../../assets/round-mail.svg"

const ContactUs = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        setIsSending(true);
        console.log("Email sending...");

        emailjs
            .sendForm(process.env.REACT_APP_EMAILJS_SERVICE_API_KEY, process.env.REACT_APP_EMAILJS_TEMPLATE_API_KEY, form.current, {
                publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_API_KEY,
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    handleShowSuccessAlert();
                    setIsSending(false);
                },
                (error) => {
                    console.log('FAILED!', error.text);
                    handleShowErrorAlert();
                    setIsSending(false);
                },
            );
    };

    const [isSending, setIsSending] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);

    const handleShowSuccessAlert = () => {
        setShowSuccessAlert(true);
    };

    const handleShowErrorAlert = () => {
        setShowErrorAlert(true);
    };

    const handleClose = () => {
        setShowSuccessAlert(false);
        setShowErrorAlert(false);
    };

    return (
        <Wrapper>
            <ContactUsContainer>
                <ContactContainer>
                    <h2>Let’s connect!</h2>
                    <p>Have a question? We are ready to answer! Fill out the form and we will contact you as soon as possible.</p>
                    <form id="contactUsForm" ref={form} onSubmit={sendEmail}>
                        <input type="text" id="name" name="user_name" placeholder="Your company’s name" required />
                        <input type="email" id="email" name="user_email" placeholder="Your email address" required />
                        <textarea type="text" id="message" name="message" placeholder="Your message" required />
                        <button className="white-button">{!isSending ? 'Send to Glacies' : 'Sending...'}</button>
                        {showSuccessAlert && (
                            <Alert
                            message="Email is sent successfully!"
                            type="success"
                            onClose={handleClose}
                            />
                        )}
                        {showErrorAlert && (
                            <Alert
                            message="Email sending failed!"
                            type="error"
                            onClose={handleClose}
                            />
                        )}
                    </form>
                    
                </ContactContainer>
                <OurSocialsContainer>
                    <h2>Our Socials</h2>
                    <p>Have a question? We are ready to answer! Fill out the form and we will contact you as soon as possible.</p>
                    <SocialsContainer>
                        <SocialContainer>
                            <img src={Social1} alt="LinkedIn" />
                            <p>company/glacies</p>
                        </SocialContainer>
                        <SocialContainer>
                            <img src={Social2} alt="Instagram" />
                            <p>glacies</p>
                        </SocialContainer>
                        <SocialContainer>
                            <img src={Social3} alt="Email" />
                            <p>info@glacies.com</p>
                        </SocialContainer>
                    </SocialsContainer>
                </OurSocialsContainer>
            </ContactUsContainer>
        </Wrapper>
    )
};

export default ContactUs;