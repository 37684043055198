import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const BodyWrapper = styled.div`
    max-width: 1440px;
    width: calc(100% - 160px);
    margin: 0 80px;

    @media screen and (max-width: 830px) {
        width: calc(100% - 48px);
        margin: 0 24px;
    }
`;

export const BlackBodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 160px);
    border-radius: 40px 40px 0px 0px;
    background: #171616;
    padding: 0 80px;

    @media screen and (max-width: 830px) {
        width: calc(100% - 48px);
        padding: 0 24px;
    }
`;