import React from 'react';

// Components
import { Wrapper, BodyWrapper } from '../components/BodyWrapper/BodyWrapper.styles';
import Header from '../components/Header';
import ContactUs from '../components/ContactUs';
import Footer from '../components/Footer';

const ContactPage = () => {

    return (
        <>
            <Header />
            <Wrapper>
                <BodyWrapper>
                    <ContactUs />
                </BodyWrapper>
            </Wrapper>
            <Footer />
        </>
    );

}

export default ContactPage;