import React, { useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Wrapper, ImgContainer, TabsContainer, ButtonsContainer, MobileHeaderContainer, MenuContainer, MenuImgWrapper, LinksContainer } from "./Header.styles";

import Logo from "../../assets/logo-header.svg"
import MenuImg from "../../assets/menu.png";

const Header = () => {
    const [showMenu, setShowMenu] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const isCurrentPage = (path) => {
        return location.pathname === path;
    };

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    return (
        <>
            <Wrapper>
                <ImgContainer onClick={() => navigate('/')} src={Logo} alt="logo" />
                
                <ButtonsContainer>
                    <TabsContainer>
                        <p className={isCurrentPage('/') ? 'active-page' : ''} onClick={() => navigate('/')}>Home</p>
                        <p className={isCurrentPage('/how-it-works') ? 'active-page' : ''} onClick={() => navigate('/how-it-works')}>How it works?</p>
                        <p className={isCurrentPage('/about-us') ? 'active-page' : ''} onClick={() => navigate('/about-us')}>About us</p>
                        <p className={isCurrentPage('/contact') ? 'active-page' : ''} onClick={() => navigate('/contact')}>Contact</p>
                    </TabsContainer>

                    <button onClick={() => window.open('https://web.glacies.bio/login', '_blank')} className="black-button">Log in</button>
                    <button onClick={() => window.open('https://web.glacies.bio', '_blank')} className="white-button">Join us</button>
                </ButtonsContainer>
            </Wrapper>
            <MobileHeaderContainer>
                <MenuContainer>
                    <img onClick={() => navigate('/')} src={Logo} alt="logo" />
                    <MenuImgWrapper onClick={toggleMenu}>
                        <img
                            src={MenuImg}
                            alt="Menu"
                            style={{
                                transform: showMenu ? 'rotate(-90deg)' : 'rotate(0)',
                            }}
                        />
                    </MenuImgWrapper>
                </MenuContainer>
                {showMenu && (
                    <LinksContainer>
                        <p className={isCurrentPage('/') ? 'active-page' : ''} onClick={() => navigate('/')}>Home</p>
                        <p className={isCurrentPage('/how-it-works') ? 'active-page' : ''} onClick={() => navigate('/how-it-works')}>How it works?</p>
                        <p className={isCurrentPage('/about-us') ? 'active-page' : ''} onClick={() => navigate('/about-us')}>About us</p>
                        <p className={isCurrentPage('/contact') ? 'active-page' : ''} onClick={() => navigate('/contact')}>Contact</p>
                        <ButtonsContainer>
                            <button onClick={() => window.open('https://web.glacies.bio/login', '_blank')} className="black-button">Log in</button>
                            <button onClick={() => window.open('https://web.glacies.bio', '_blank')} className="white-button">Join us</button>
                        </ButtonsContainer>
                    </LinksContainer>
                )}
            </MobileHeaderContainer>
        </>
    )
};

export default Header;