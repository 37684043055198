import React from "react";
import { Wrapper, TopContainer, MidContainer, LinksContainer, BottomContainer, ShareContainer } from "./Footer.styles";

import Logo from "../../assets/logo-header.svg"
import Instagram from "../../assets/ig.svg"
import Twitter from "../../assets/x.svg"
import Linkedin from "../../assets/linkedin.svg"
import Medium from "../../assets/medium.svg"

const Footer = () => {

    return (
        <Wrapper>
            <TopContainer>
                <img src={Logo} alt="logo" />
            </TopContainer>
            
            <MidContainer>
                <LinksContainer>
                    <a href='/how-it-works'>How it works?</a>
                    <a href='/about-us'>About us</a>
                    <a href='/#collaborations'>Collaborations</a>
                    <a href='/#solution'>Our Solution</a>
                </LinksContainer>
                <ShareContainer>
                    <img src={Instagram} alt="Social media" />
                    <img src={Twitter} alt="Social media" />
                    <img src={Linkedin} alt="Social media" />
                    <img src={Medium} alt="Social media" />
                </ShareContainer>
            </MidContainer>
            <BottomContainer>
                <p>Copyright © 2024. Glacies.bio  All rights reserved.</p>
                <LinksContainer>
                    <a href='/#'>Terms & Conditions</a>
                    <a href='/#'>Privacy Policy</a>
                </LinksContainer>
            </BottomContainer>
        </Wrapper>
        
    )
};

export default Footer;