import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 80px 0;

    @media screen and (max-width: 1024px) {
        img {
            display: none;
        }
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;

    h1 {
        max-width: 600px;
        font-size: 60px;
        font-weight: 500;
        line-height: 70px;
        color: #D88EFD;
        margin-bottom: 12px;
    }

    p {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        color: rgba(243, 246, 255, 0.60);
    }

    span {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        color: rgba(243, 246, 255, 0.80);
    }

    @media screen and (max-width: 550px) {
        h1 {
            font-size: 48px;
        }
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    gap: 20px;
    margin: 40px 0 22px 0;

    button {
        height: 56px;
        font-size: 20px;
        font-weight: 500;
    }

    @media screen and (max-width: 550px) {
        flex-direction: column;

        button {
            width: 100%;
        }
    }
`;