import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 90px 0 100px 0;

    @media screen and (max-width: 1100px) {
        align-items: baseline;
    }
`;

export const HowItWorksContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 40px;
    width: 100%;

    @media screen and (max-width: 1100px) {
        flex-direction: column;
        width: fit-content;
    }

    @media screen and (max-width: 690px) {
        width: 100%;
    }
`;

export const HowItWorksTextContainer = styled.div`
    max-width: 550px;

    h3 {
        font-size: 14px;
        font-weight: 500;
    }

    h1 {
        font-size: 40px;
        font-weight: 500;
        line-height: 56px;
        margin-bottom: 48px;
    }

    p {
        margin-top: 44px;
        color: #EED0F2;
    }
`;