import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    width: 100%;
    margin: 100px 0;

    h6 {
        color: #D88EFD;
        font-size: 14px;
        font-weight: 500;
    }

    h2 {
        font-size: 40px;
        font-weight: 500;
        line-height: 56px;
    }

    @media screen and (max-width: 500px) {
        margin: 50px 0;
    }
`;

export const FactsContainer = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 56px;
    gap: 40px;
`;

export const FactContainer = styled.div`
    width: 242px;
    height: 244px;
    border-radius: 24px;
    border: 1px solid rgba(119, 119, 119, 0.30);
    background: rgba(250, 250, 250, 0.05);
    padding: 24px;

    @media screen and (max-width: 1465px) {
        width: 365px;
    }

    @media screen and (max-width: 1132px) {
        width: 340px;
    }

    @media screen and (max-width: 1000px) {
        width: 242px;
    }

    @media screen and (max-width: 802px) {
        width: 220px;
    }

    @media screen and (max-width: 500px) {
        width: 100%;
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 16px;

    h5 {
        color: #FFF;
        font-size: 28px;
        font-weight: 500;
        line-height: 34px;
    }

    p {
        color: #EED0F2;
        margin-top: 8px;
    }
`;