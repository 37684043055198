import React, { useEffect } from 'react';
import { GlobalStyle } from './GlobalStyle';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

//Pages
import HomePage from './pages/HomePage';
import AboutUsPage from './pages/AboutUsPage';
import HowItWorksPage from './pages/HowItWorksPage';
import ContactPage from './pages/ContactPage';

function App() {
  //Navigate by id
  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView();
        }
      }
    };

    window.addEventListener('hashchange', handleHashChange);

    handleHashChange();

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return (
    <Router>
      <GlobalStyle />
      
      <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/about-us' element={<AboutUsPage />} />
          <Route path='/how-it-works' element={<HowItWorksPage />} />
          <Route path='/contact' element={<ContactPage />} />
      </Routes>
    </Router>
  );
}

export default App;
