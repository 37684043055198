import React from "react";
import { useNavigate } from 'react-router-dom';
import { Wrapper, SkincareContainer } from "./PersonalisedSkincare.styles";

import SkincareImg from "../../assets/skincare.png"

const PersonalisedSkincare = () => {
    const navigate = useNavigate();

    return (
        <Wrapper>
            <SkincareContainer>
                <h2>Personalised Skincare</h2>
                <p>We merge the latest advancements in sequencing technology and microbiome research with state-of-the-art AI models to uniquely characterize the skin as a whole considering both visual and hidden biology underneath. Glacies AI engine combines three sources of information, skin microbiome collected by a swab of the skin, a meticulously drafted questionnaire to understand your unique habits, and facial image to characterize the individual skin profile. Our technology creates a customized set of skincare products that fit best to individuals.</p>
                <button onClick={() => navigate('/how-it-works')} className="white-button">See How it Works</button>
            </SkincareContainer>
            <img src={SkincareImg} alt="Personalised Skincare" />
        </Wrapper>
        
    )
};

export default PersonalisedSkincare;