import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 90px 0;
`;

export const AboutUsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
`;

export const TitleTextContainer = styled.div`
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1000px) {
        flex-direction: column;
    }
`;

export const TitleContainer = styled.div`
    min-width: 398px;
    margin-bottom: 48px;

    h5 {
        font-size: 14px;
        font-weight: 500;
    }

    h1 {
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: 56px;
    }

    @media screen and (max-width: 1000px) {
        min-width: auto;
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 44px;
    max-width: 834px;

    p {
        color: #EED0F2;
    }
`;

export const MapContainer = styled.div`
    text-align: center;

    img {
        margin-top: 200px;
        max-width: 884px;
        width: 100%;
    }
`;