import styled from "styled-components";

export const Wrapper = styled.header`
    display: flex;
    height: 70px;
    padding: 30px 80px;
    justify-content: space-between;
    align-items: center;
    background: rgba(6, 4, 14, 0.20);
    backdrop-filter: blur(7px);

    p {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        cursor: pointer;

        &:hover {
            color: #DBB1C6;
        }
    }

    @media screen and (max-width: 1024px) {
        display: none;
    }
`;

export const ImgContainer = styled.img`
    width: 144px;
    height: 43px;
    cursor: pointer;
`;

export const TabsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 40px;
    margin-right: 24px;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    gap: 16px;

    button {
        height: 50px;
        border: 1px solid rgba(255, 255, 255, 0.50);
    }

    @media screen and (max-width: 1024px) {
        justify-content: space-between;
        margin-top: 40px;
    }
`;

export const MobileHeaderContainer = styled.div`
    display: none;

    @media screen and (max-width: 1024px) {
        display: block;
    }
`;

export const MenuContainer = styled.div`
    display: flex;
    height: 70px;
    padding: 30px 80px;
    justify-content: space-between;
    align-items: center;
    background: rgba(6, 4, 14, 0.20);
    backdrop-filter: blur(7px);

    img {
        width: 144px;
        height: 43px;
        cursor: pointer;
    }

    @media screen and (max-width: 830px) {
        padding: 30px 24px;
    }
`;

export const MenuImgWrapper = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    cursor: pointer;
    background: transparent;

    img {
        width: 31px;
        height: 31px;
    }
`;

export const LinksContainer = styled.div`
    padding: 10px 50px 50px 50px;
    background: #17171C;
    position: absolute;
    width: calc(100% - 100px);
    left: 0;
    z-index: 9;

    p {
        margin-top: 40px;
        padding-bottom: 16px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.25);
        cursor: pointer;

        &:hover {
            color: #DBB1C6;
        }
    }
`;