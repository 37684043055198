import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 70px 0;

    @media screen and (max-width: 1142px) {
        width: 100%;

        img {
            width: inherit;
        }
    }
`;

export const SkincareContainer = styled.div`
    max-width: 774px;
    text-align: center;
    margin-bottom: 149px;

    h2 {
        font-size: 40px;
        font-weight: 500;
        line-height: 56px;
    }

    p {
        color: #EED0F2;
        margin: 24px 0 40px 0;
    }

    button {
        height: 56px;
    }

    @media screen and (max-width: 500px) {
        button {
            width: 100%;
        }  
    }
`;