import React from "react";
import { Wrapper, TextAndImageContainer, TextContainer, TitleAndTextContainer } from "./TextAndImage.styles";

import ScreenImg1 from "../../assets/microbiome-screen.png"
import ScreenImg2 from "../../assets/skin-profile-screen.png"
import ScreenImg3 from "../../assets/routine-screen.png"

const TextAndImage = () => {
    return (
        <Wrapper>
            <TextAndImageContainer>
                <TextContainer>
                    <h4>Your Skin Microbiome</h4>
                    <TitleAndTextContainer>
                        <h5>Skin Age</h5>
                        <p>You and your microbiome have another common interest, not wanting to age. Your biological skin age can determine your susceptibility to not only skin conditions but also multiple drug resistant pathogens. Fear not we let you track and be on top of your faical health so you can age gracefully and slowly.</p>
                    </TitleAndTextContainer>
                    <TitleAndTextContainer>
                        <h5>Microbiome Diversity</h5>
                        <p>Microbiome diversity is a significant determinant of your susceptibility to dermatologic diseases and can be an early warning. Your microbiome diversity rate is lower than the optimal range. But fear not, we have recommendations for you how to be on top of your skin game.</p>
                    </TitleAndTextContainer>
                </TextContainer>
                <img className="screen-img" src={ScreenImg1} alt="Microbiome screen" />
            </TextAndImageContainer>
            <TextAndImageContainer className="second-info-container">
                <TextContainer>
                    <h4>Your Skin Profile</h4>
                    <TitleAndTextContainer>
                        <h5>Actinobacteria</h5>
                        <p>You have a slightly higher average prevalence of Actinobacteria than an average person at your age. While this is perfectly normal, you are predisposed for acne outbursts.</p>
                    </TitleAndTextContainer>
                    <TitleAndTextContainer>
                        <h5>Firmicutes</h5>
                        <p>Lower levels of Firmicutes might increase your risk of skin infections. Your personalised probiotic regiment will focus on improving the balance between Actinobacterium and Firmicutes.</p>
                    </TitleAndTextContainer>
                </TextContainer>
                <img className="screen-img" src={ScreenImg2} alt="Microbiome Diversity screen" />
            </TextAndImageContainer>
            <TextAndImageContainer>
                <TextContainer>
                    <h4>Your Personalized Skincare Routine</h4>
                    <TitleAndTextContainer>
                        <h5>Skin Age</h5>
                        <p>You and your microbiome have another common interest, not wanting to age. Your biological skin age can determine your susceptibility to not only skin conditions but also multiple drug resistant pathogens. Fear not we let you track and be on top of your faical health so you can age gracefully and slowly.</p>
                    </TitleAndTextContainer>
                    <TitleAndTextContainer>
                        <h5>Microbiome Diversity</h5>
                        <p>Microbiome diversity is a significant determinant of your susceptibility to dermatologic diseases and can be an early warning. Your microbiome diversity rate is lower than the optimal range. But fear not, we have recommendations for you how to be on top of your skin game.</p>
                    </TitleAndTextContainer>
                </TextContainer>
                <img className="screen-img" src={ScreenImg3} alt="Microbiome Diversity screen" />
            </TextAndImageContainer>
        </Wrapper>
        
    )
};

export default TextAndImage;