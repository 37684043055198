import React from "react";
import { Wrapper, AboutUsContainer, TitleTextContainer, TitleContainer, TextContainer, MapContainer } from "./AboutUs.styles";

import Img from "../../assets/map.png"


const AboutUs = () => {
    return (
        <Wrapper>
            <AboutUsContainer>
                <TitleTextContainer>
                    <TitleContainer>
                        <h5>GET INFORMED</h5>
                        <h1>About us</h1>
                    </TitleContainer>
                    <TextContainer>
                        <p>Glacies Biome, a Luxembourg-based biotech startup, merges the forefront of synthetic
                            biology and artificial intelligence to disrupt skincare industry. Our collaboration with
                            skincare professionals fosters a dynamic approach to research and development,
                            addressing the evolving needs of the field, truly personalized and precise skincare
                            routine.
                        </p>
                        <p>Our mission is to offer skincare solutions that aim to diagnose aesthetic facial
                            problems, restore skin health, and prevent aging with our next-generation sequencing
                            analysis of the facial microbiome and custom developed Al models. With our
                            Connected Skincare software, skincare professionals would easily collect the
                            diagnostics, treatment and other analytical data for their patients in one place and
                            improve the quality of their treatment with AI-powered solutions.</p>
                        <p>We are committed to delivering our pioneering beauty and skin health solutions inLuxembourg, France, Germany, and the USA.</p>
                    </TextContainer>
                </TitleTextContainer>
                <MapContainer>
                    <img src={Img} alt="map" />
                </MapContainer>
            </AboutUsContainer>
        </Wrapper>

    )
};

export default AboutUs;