import React from "react";
import { Wrapper, FactsContainer, FactContainer, TextContainer } from "./Facts.styles";

import Img1 from "../../assets/virus.svg"
import Img2 from "../../assets/chip.svg"
import Img3 from "../../assets/database.svg"
import Img4 from "../../assets/ok.svg"

const Facts = () => {
    return (
        <Wrapper>
            <h6>More about us</h6>
            <h2>Why use Glacies?</h2>
            <FactsContainer>
                <FactContainer>
                    <img src={Img1} alt="Virus" />
                    <TextContainer>
                        <h5>50% of our face is bacteria</h5>
                        <p>Balanced microbiome slows the skin aging & Serves the primary defense for our skin health</p>
                    </TextContainer>
                </FactContainer>
                <FactContainer>
                    <img src={Img2} alt="Chip" />
                    <TextContainer>
                        <h5>AI-powered holistic skin profiling</h5>
                        <p>Personalized skincare routine for precise treatment </p>
                    </TextContainer>
                </FactContainer>
                <FactContainer>
                    <img src={Img3} alt="Database" />
                    <TextContainer>
                        <h5>Connected Skincare</h5>
                        <p>Digital platform enabling skin professionals to analyze and track the treatment progress</p>
                    </TextContainer>
                </FactContainer>
                <FactContainer>
                    <img src={Img4} alt="Smile" />
                    <TextContainer>
                        <h5>Self-Awareness</h5>
                        <p>Personalized skincare routine for precise treatment </p>
                    </TextContainer>
                </FactContainer>
            </FactsContainer>
        </Wrapper>
        
    )
};

export default Facts;